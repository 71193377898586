import './Notification.css'
import {
  IonCheckbox,
  IonIcon,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { MY_NOTIFICATION, NOTIFICATION_EXERCISE, SAVE_NOTIFICATION } from '../../components/common/DbConstants'
import { useEffect, useState } from 'react'
import { time } from 'ionicons/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { requestForToken, sendRegistrationToken } from '../../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { setExerciseNotification } from '../../redux/actions'
import TimeInput from '../common/TimeInput'
import { ConfigDataExercise } from '../../types/NotificationTypes'
import { getUtcConfigDataExercise, getZonedConfigDataExercise } from './timezone-utils'

export const ExerciseNotifications: React.FC = () => {
  const { t } = useTranslation()
  const exerciseNotification = useSelector((state: any) => state.exerciseNotification)
  const userData = useSelector((state: any) => state.userData)
  const [configDataExercise, setConfigDataExercise]: [ConfigDataExercise[], Function] = useState(exerciseNotification)
  const [isInitialMount, setIsInitialMount] = useState(true)
  const dispatch = useDispatch()

  const [saveExerciseNotification] = useMutation(SAVE_NOTIFICATION)

  const refreshToken = () => {
    requestForToken()
      .then((fcmToken: any) => {
        if (fcmToken != null && userData.id) sendRegistrationToken(fcmToken, userData.id)
      })
      .catch(function (error: any) { console.log('error getting token', error) })
  }

  const saveNotification = async () => {
    refreshToken()
    const configDataExerciseUtc: ConfigDataExercise[] = getUtcConfigDataExercise(configDataExercise)
    const configData = JSON.stringify(configDataExerciseUtc)
    await saveExerciseNotification({ variables: { notificationType: NOTIFICATION_EXERCISE, configData } })
  }

  const [getMyNotifications] = useLazyQuery(MY_NOTIFICATION, {
    variables: { notificationType: NOTIFICATION_EXERCISE },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const myNotification: ConfigDataExercise[] = data?.myNotification ? JSON.parse(data.myNotification.configData) : null

      if (myNotification) {
        const myNotificationZoned = getZonedConfigDataExercise(myNotification)
        setConfigDataExercise([...myNotificationZoned])
        dispatch(setExerciseNotification([...myNotificationZoned]))
      }
    }
  })

  useEffect(() => {
    if (isInitialMount) {
      getMyNotifications()
      setIsInitialMount(false)
    } else {
      saveNotification()
    }
  }, [configDataExercise])

  const setTime = (time: string, index: number) => {
    const newConfigData = [...configDataExercise]
    newConfigData[index].time = time
    setConfigDataExercise([...newConfigData])
  }

  return (
    <IonList>
        {
          configDataExercise.map((_: any, i: number) =>
            <div key={i} className='config-exercise-row'>

                <IonItem className='exercise-day'>
                  <IonCheckbox mode="ios" slot="start" onClick={() => {
                    configDataExercise[i].active = !configDataExercise[i].active
                    setConfigDataExercise([...configDataExercise])
                  }} checked={configDataExercise[i].active}
                  />
                <IonLabel >{t(`week.${configDataExercise[i].day}`)}</IonLabel>
                </IonItem>

                <TimeInput
                  time={configDataExercise[i].time}
                  setTime={setTime}
                  index={i}
              />
              <IonIcon className="exercise-icon" icon={time}></IonIcon>

            </div>
          )
        }
    </IonList>
  )
}
