import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonToggle
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { MY_NOTIFICATION, NOTIFICATION_ALCOHOL_SUGAR, SAVE_NOTIFICATION } from '../common/DbConstants'
import { useEffect, useState } from 'react'
import { moonOutline, radio, refreshOutline, sunnyOutline } from 'ionicons/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import PlusMinusNumber from '../common/PlusMinusNumber'
import { useDispatch, useSelector } from 'react-redux'
import { requestForToken, sendRegistrationToken } from '../../firebase'
import { setReminderNotification } from '../../redux/actions'
import TimeInput from '../common/TimeInput'
import { ConfigDataReminder } from '../../types/NotificationTypes'
import { getUtcConfigData, getZonedConfigData } from './timezone-utils'

export const ReminderNotifications: React.FC = () => {
  const { t } = useTranslation()
  const reminderNotification = useSelector((state: any) => state.reminderNotification)
  const userData = useSelector((state: any) => state.userData)
  const [configDataReminder, setConfigDataReminder]: [ConfigDataReminder[], Function] = useState(reminderNotification)
  const [isInitialMount, setIsInitialMount] = useState(true)
  const dispatch = useDispatch()

  const [saveReminderNotification] = useMutation(SAVE_NOTIFICATION)

  const refreshToken = () => {
    requestForToken()
      .then((fcmToken: any) => {
        if (fcmToken != null && userData.id) sendRegistrationToken(fcmToken, userData.id)
      })
      .catch(function (error: any) { console.log('error getting token', error) })
  }

  const saveNotification = async () => {
    refreshToken()
    const configDataReminderUtc = getUtcConfigData(configDataReminder)
    const configData = JSON.stringify(configDataReminderUtc)
    await saveReminderNotification({ variables: { notificationType: NOTIFICATION_ALCOHOL_SUGAR, configData } })
  }

  const [getMyNotifications] = useLazyQuery(MY_NOTIFICATION, {
    variables: { notificationType: NOTIFICATION_ALCOHOL_SUGAR },
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const myNotification: ConfigDataReminder[] = data?.myNotification ? JSON.parse(data.myNotification.configData) : null

      if (myNotification) {
        const myNotificationZoned = getZonedConfigData(myNotification)
        setConfigDataReminder([...myNotificationZoned])
        dispatch(setReminderNotification([...myNotificationZoned]))
      }
    }
  })

  useEffect(() => {
    if (isInitialMount) {
      getMyNotifications()
      setIsInitialMount(false)
    } else {
      saveNotification()
    }
  }, [configDataReminder])

  const setInitTime = (time: string) => {
    const newConfigData = [...configDataReminder]
    newConfigData[0].initTime = time
    setConfigDataReminder([...newConfigData])
  }

  const setOffTime = (time: string) => {
    const newConfigData = [...configDataReminder]
    newConfigData[0].offTime = time
    setConfigDataReminder([...newConfigData])
  }

  return (
    <IonList>
      <IonItem>
        <IonIcon icon={radio} />
        <IonLabel className="ion-padding">{t('notification.activated')}</IonLabel>
        <IonToggle slot="end" checked={configDataReminder[0].active} onClick={() => {
          configDataReminder[0].active = !configDataReminder[0].active
          setConfigDataReminder([...configDataReminder])
        }} />

      </IonItem>

      <IonItem>
        <IonIcon icon={sunnyOutline} />
        <IonLabel className="ion-padding">{t('notification.on')}</IonLabel>
        <TimeInput
          time={configDataReminder[0].initTime}
          setTime={setInitTime}
        />
      </IonItem>

      <IonItem>
        <IonIcon icon={moonOutline} />
        <IonLabel className="ion-padding">{t('notification.off')}</IonLabel>
        <TimeInput
          time={configDataReminder[0].offTime}
          setTime={setOffTime}
        />
      </IonItem>

      <IonItem>
        <IonIcon icon={refreshOutline}></IonIcon>
        <IonLabel className="ion-padding">{t('notification.frequency')}</IonLabel>
        <PlusMinusNumber
          itemVar={configDataReminder[0].frequency}
          setItemVarFunction={
            (value: number) => {
              configDataReminder[0].frequency = value
              setConfigDataReminder([...configDataReminder])
            }}
        />
      </IonItem>
    </IonList>
  )
}
