import axios, { AxiosError } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { BASE_URL } from '../types/constants'

interface SuccessResponse {
  message: string;
  success: true;
}

interface ErrorResponse {
  message: string;
  success: false;
  error: {
    code: string;
  };
}

type CreateUserInput = { name: string; email: string; password: string };
type KnownError = AxiosError<ErrorResponse>;

const createUser = async (input: CreateUserInput): Promise<SuccessResponse> => {
  const response = await axios.post<SuccessResponse>(`${BASE_URL}/v2/auth/register`, input)
  return response.data
}

export const useCreateUser = (): UseMutationResult<SuccessResponse, KnownError, CreateUserInput> => {
  return useMutation<SuccessResponse, KnownError, CreateUserInput>(createUser)
}
