import './Notification.css'
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonToggle
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { MY_NOTIFICATION, NOTIFICATION_WATER, SAVE_NOTIFICATION } from '../../components/common/DbConstants'
import PlusMinusNumber from '../../components/common/PlusMinusNumber'
import { useEffect, useState } from 'react'
import { moonOutline, radio, refreshOutline, sunnyOutline } from 'ionicons/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { requestForToken, sendRegistrationToken } from '../../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { setWaterNotification } from '../../redux/actions'
import TimeInput from '../common/TimeInput'
import { ConfigDataWater } from '../../types/NotificationTypes'
import { convertTimeToLocal, convertTimeToUTC } from './timezone-utils'

export const WaterNotifications: React.FC = () => {
  const { t } = useTranslation()
  const waterNotification = useSelector((state: any) => state.waterNotification)
  const userData = useSelector((state: any) => state.userData)
  const [configDataWater, setConfigDataWater] = useState<ConfigDataWater[]>(waterNotification)
  const [isInitialMount, setIsInitialMount] = useState(true)
  const dispatch = useDispatch()

  const [saveWaterNotification] = useMutation(SAVE_NOTIFICATION)

  const refreshToken = () => {
    requestForToken()
      .then((fcmToken: any) => {
        if (fcmToken != null && userData.id) sendRegistrationToken(fcmToken, userData.id)
      })
      .catch(function (error: any) { console.log('error getting token', error) })
  }

  const saveNotification = async () => {
    refreshToken()
    const initTime = convertTimeToUTC(configDataWater[0].initTime)
    const offTime = convertTimeToUTC(configDataWater[0].offTime)

    const configDataWaterUtc = [{
      ...configDataWater[0],
      initTime,
      offTime
    }]

    const configData = JSON.stringify(configDataWaterUtc)
    await saveWaterNotification({ variables: { notificationType: NOTIFICATION_WATER, configData } })
  }

  const [getMyNotifications] = useLazyQuery(MY_NOTIFICATION, {
    variables: { notificationType: NOTIFICATION_WATER },
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const myNotification: ConfigDataWater[] = data?.myNotification ? JSON.parse(data.myNotification.configData) : null

      if (myNotification) {
        const initTime = convertTimeToLocal(myNotification[0].initTime)
        const offTime = convertTimeToLocal(myNotification[0].offTime)

        const myNotificationZoned = [{
          ...myNotification[0],
          initTime,
          offTime
        }]

        setConfigDataWater([...myNotificationZoned])
        dispatch(setWaterNotification([...myNotificationZoned]))
      }
    }
  })

  useEffect(() => {
    if (isInitialMount) {
      getMyNotifications()
      setIsInitialMount(false)
    } else {
      saveNotification()
    }
  }, [configDataWater])

  const setInitTime = (time: string) => {
    const newConfigData = [...configDataWater]
    newConfigData[0].initTime = time
    setConfigDataWater([...newConfigData])
  }

  const setOffTime = (time: string) => {
    const newConfigData = [...configDataWater]
    newConfigData[0].offTime = time
    setConfigDataWater([...newConfigData])
  }

  return (
    <IonList>
      <IonItem>
        <IonIcon icon={radio} />
        <IonLabel className="ion-padding">{t('notification.activated')}</IonLabel>
        <IonToggle slot="end" checked={configDataWater[0].active} onClick={() => {
          configDataWater[0].active = !configDataWater[0].active
          setConfigDataWater([...configDataWater])
        }} />
      </IonItem>

      <IonItem>
        <IonIcon icon={sunnyOutline} />
        <IonLabel className="ion-padding">{t('notification.on')}</IonLabel>
        <TimeInput
          time={configDataWater[0].initTime}
          setTime={setInitTime}
        />
      </IonItem>

      <IonItem>
        <IonIcon icon={moonOutline} />
        <IonLabel className="ion-padding">{t('notification.off')}</IonLabel>
        <TimeInput
          time={configDataWater[0].offTime}
          setTime={setOffTime}
        />
      </IonItem>

      <IonItem>
        <IonIcon icon={refreshOutline}></IonIcon>
        <IonLabel className="ion-padding">{t('notification.frequency')}</IonLabel>
        <PlusMinusNumber
          itemVar={configDataWater[0].frequency}
          setItemVarFunction={
            (value: number) => {
              configDataWater[0].frequency = value
              setConfigDataWater([...configDataWater])
            }}
        />
      </IonItem>
    </IonList>
  )
}
